<template>
  <section class="countryCalendar">
    <div class="container">
      <h2 class="sectionTitle bef">{{ propsData.title }}</h2>
      <p class="smallText">
        {{ propsData.subtitle }}
      </p>
      <div class="timeWrapper">
        <div class="col" v-for="(season, idx) in propsData.items" :key="idx">
          <p class="title">{{ propsData.seasons[idx] }}</p>
          <div class="month" v-for="(month, idx) in season" :key="idx">
            <p class="name">{{ month.name }}</p>
            <ul>
              <li v-for="(item, idx) in month.list" :key="idx">
                <!-- <router-link :to="{name: 'Contry', params: {countryName: item.slug}}"> -->
                <router-link
                  :to="{
                    name: 'CatalogChild',
                    params: {
                      month: month.slug,
                      slug: item.slug,
                      locale: $i18n.locale == 'en' ? null : $i18n.locale,
                    }
                  }"
                >
                  <i class="icon" :class="item.image"></i>
                  <span>{{ item.name }}</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    propsData: {
      type: [Array, Object]
    }
  }
};
</script>

<style lang="sass" scoped>
.countryCalendar
    @include sectionPadding
    text-align: center

.timeWrapper
    display: grid
    grid-template-columns: repeat(4, 1fr)
    grid-gap: 28px
    margin-top: 77px
    @media (min-width: 769px) and (max-width: 1024px)
        grid-template-columns: 1fr
    @media (min-width: 577px) and (max-width: 768px)
        grid-template-columns: repeat(2, 1fr)
    @media (max-width: 576px)
        grid-template-columns: 1fr
    .title
        font-style: normal
        font-weight: 500
        font-size: 25px
        line-height: 140%
        color: #2A4651
        margin-bottom: 25px
        margin-left: 25px
.col
    text-align: left
    @media (min-width: 769px) and (max-width: 1024px)
        display: grid
        grid-template-columns: repeat(3, 1fr)
        column-gap: 40px
        .month
            margin-bottom: 0px !important
        .title
            grid-column: 1/4

    .month
        background-color: #28292F
        padding: 25px 0px
        &:not(:last-of-type)
            margin-bottom: 28px
        .name
            font-weight: 600
            font-size: 16px
            line-height: 130%
            color: #FFFFFF
            margin-top: 0px
            padding: 7px 25px
            margin-bottom: 15px
        li
            font-weight: 300
            font-size: 17px
            line-height: 150%
            color: #B6B6B6
            padding: 7px 25px
            cursor: pointer
            a
                position: relative
                width: 100%
                display: block
                &::after
                    content: ''

            &:hover
                background-color: rgba(255, 255, 255, 0.05)
                a, i
                    color: $c-btn
                a::after
                    font-family: "dreamTravel" !important
                    content: ""
                    position: absolute
                    right: 0px
                    font-size: 12px
            i
                margin-right: 10px
.smallText
    max-width: 586px
    margin: 0 auto
</style>
